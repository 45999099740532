// extracted by mini-css-extract-plugin
export var brand = "styles-module--brand--kYbOH";
export var cBody = "styles-module--cBody--IH1ji";
export var cIcon = "styles-module--cIcon--MGhsc";
export var cIconLink = "styles-module--cIconLink--tV2sx";
export var cIconLinkInstagram = "styles-module--cIconLinkInstagram--P4kQG";
export var cIconLinkMail = "styles-module--cIconLinkMail--61E-h";
export var cIconWrapper = "styles-module--cIconWrapper--FegCS";
export var cLink = "styles-module--cLink--6KCc1";
export var cLinkActive = "styles-module--cLinkActive--Kh7gx";
export var cNav = "styles-module--cNav--2a+X8";
export var closeButton = "styles-module--closeButton--JPumX";
export var container = "styles-module--container--EwvwY";
export var header = "styles-module--header--qfgGV";
export var menu = "styles-module--menu--PwICM";
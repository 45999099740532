// extracted by mini-css-extract-plugin
export var cBrand = "styles-module--cBrand--j4gVF";
export var cContainer = "styles-module--cContainer--BBki2";
export var cFooter = "styles-module--cFooter--7gKxU";
export var cFooterLine = "styles-module--cFooterLine--Wdnik";
export var cIcon = "styles-module--cIcon--9XL+2";
export var cIconLink = "styles-module--cIconLink--VmRg7";
export var cIconLinkInstagram = "styles-module--cIconLinkInstagram--Cqvvm";
export var cIconLinkMail = "styles-module--cIconLinkMail--O+Mhs";
export var cLink = "styles-module--cLink--kSQn0";
export var cLinkDatenschutz = "styles-module--cLinkDatenschutz--gMqT5";
export var cLinkImpressum = "styles-module--cLinkImpressum--M-VQB";
export var cLinkText = "styles-module--cLinkText--bdyuL";
export var cMenu = "styles-module--cMenu--nLRvN";
export var cSubBrand = "styles-module--cSubBrand--8pMPz";